#container-product {
    grid-row-start: 1;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 6;
}

#container-infos {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 2;
    grid-column-end: 6;
}

.title-container-product  {
   margin: 0.5rem;
}
.title-container-product h2 {
    background-color: $tertiaire;
    padding: 15px 25px;
    color: #FFF;
    margin-bottom: 0.5rem ;
    font-size: 1.3rem;
}

.grid-container-product {


    margin: 0.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    transition: none !important;

}
.grid-container-latest {
    margin: 0.5rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
    transition: none !important;
}


.card-product-grid {
    border: 1px solid #ccc;
    height: 300px;
    padding: 8px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    box-shadow: 3px 9px 30px -2px rgba(0, 0, 0, 0.18);

}
.footer-card-product{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
}

.card-product-grid img {
    width: 120px;
    margin: auto;
}

.card-product-grid h4 {
    font-size: 1.1rem;

}
.card-product-grid .voir-fiche-slug {
    text-decoration: none;
    background-color: #e3e4d4;
    border-radius: 32px;
    width: 6rem;
    height: 1.5rem;
    color: $text-green;
    font-weight:700;
    font-size:0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.card-product-grid p {
    text-decoration: none;
    color: $text-green;
    font-weight:bold;
    font-size: 1rem;

}
.card-product-grid span {
    position: absolute;
    margin-left: 5px;
    font-size: 10px;

}
.list-container-product {


    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    transition: none !important;


}
// ! ajout css pour list / grid

.card-product-ref, .card-product-description {
    display: none;
}

.card-product-list {
    height: 300px;
    padding: 8px;
    text-align:start;
    height : auto;
    width : 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;


}
.card-product-list img {

    margin-left: 0;
    margin: auto;

}
.card-product-list .footer-card-product {
    display: flex;
    flex-direction: column;

    justify-content: space-around;
}

.card-product-list .card-product-description {
    text-decoration: none;
    color: $text-green;
    font-weight:500;
    font-size: 0.8rem;
}
.card-product-list .card-product-ref {
    text-decoration: none;
    color: $tertiaire;
    font-weight:bold;
    font-size: 0.9rem;
    margin-top: 1rem;
}

.class-list-product1 {
    order: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0.5rem 1rem;
    width: 100%;


}

.class-list-product2 {
    order: 1;
    display: flex;
    align-items: center;
    margin: 0.5rem 0.5rem
}

.class-list-product3 {
    order:3;
    margin: 0.5rem 0.5rem
}

#container-list-grid {
    transition: none!important;
}

.card-product-electrique {
    border: 1px solid #c3c3c3;;
    height: 350px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 3px 9px 30px -2px rgba(0, 0, 0, 0.18);

}

.card-product-electrique .voir-fiche-slug {
    color: #fff;
    display: flex;
    justify-content: center;
    background: #2C533A;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.card-product-electrique .voir-fiche-slug:hover {

    background: #98B13E;
    transition : all 0.3s ease;

}



.card-product-electrique img {
    object-fit: contain;
    height: 150px;
    width: 95%;
    margin: 4px auto;
}
.card-product-electrique .card-infos {
    display: flex;
    flex-direction: column;
    background: #f1f1f1;
    padding: 10px 8px;
    height :100%;
    margin:1px;
}

.card-product-electrique .card-infos h4{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 19px;
    color: #5b5c43;
    line-height: 1.2;
    font-size: 1rem;
    text-align:start;
}

.card-product-electrique .card-infos .reference-product {
    font-style: italic;
    text-align:start;
}
.card-product-electrique .card-infos .price {

    text-align:end;
    font-weight: 700;
    font-size: 1.1rem;
    margin-top : 1rem;


}

.card-product-electrique {
    border: 1px solid #c3c3c3;;
    height: 350px;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 3px 9px 30px -2px rgba(0, 0, 0, 0.18);

}

.card-product-electrique .voir-fiche-slug {
    color: #fff;
    display: flex;
    justify-content: center;
    background: #2C533A;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

.card-product-electrique .voir-fiche-slug:hover {

    background: #98B13E;
    transition : all 0.3s ease;

}



.card-product-electrique img {
    object-fit: contain;
    height: 150px;
    width: 95%;
    margin: 4px auto;
}
.card-product-electrique .card-infos {
    display: flex;
    flex-direction: column;
    background: #f1f1f1;
    padding: 10px 8px;
    height :100%;
    margin:1px;
}

.card-product-electrique .card-infos h4{
    font-weight: 700;
    text-transform: uppercase;
    font-size: 19px;
    color: #5b5c43;
    line-height: 1.2;
    font-size: 1rem;
    text-align:start;
}

.card-product-electrique .card-infos .reference-product {
    font-style: italic;
    text-align:start;
}
.card-product-electrique .card-infos .price {

    text-align:end;
    font-weight: 700;
    font-size: 1.1rem;
    margin-top : 1rem;


}
@media (max-width: 1500px) {
    .grid-container-latest{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 1190px) {
    .grid-container-product{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 962px) {
    .grid-container-product{
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (max-width: 792px) {
    .grid-container-product, .grid-container-latest{
        grid-template-columns: repeat(1, 1fr);
    }
    #container-product {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
    }


    .card-product-grid img {
        width: 90px;
    }

}
