.top-banner {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.4em 4rem ;
    font-size: 0.95rem;
    background-color: rgba(0,0,0, 0.55);
    color: $text-color;
}
.picto-paiement {
    width: 100px;
}
.logo-header {
    margin: auto;
    width: 600px;

}

/* --------@ MEDIA query 792PX -------- */
@media (max-width: 792px) {
    .top-banner {
        display: none;
    }

    .logo-header {
        width: 280px;
    }


}
