
#container-categorie {
    grid-row-start: 1;
    grid-row-end: 5;
    grid-column-start: 1;
    grid-column-end: 2;
    max-width: 350px;
}

#container-categorie img {
    width: 100%;
    height: auto;
    margin: 0.5rem 0rem;
    filter: brightness(0.99);
    transition: filter 0.3s ease;
}
#container-categorie img:hover {
    filter: brightness(1.2);
}



.menu-container-categorie a, .menu-container-categorie ul, .menu-container-categorie li{

    transition: all 0.3s ease;

}
 .menu-container-categorie a:hover{
     font-size: 0.81rem;
     color: #7e7a6c;

 }


.sidebar-container-infos{
    background-color: $primary;
    color: $text-color;
    margin: 0.5rem 0;
    padding: 1rem;
    font-size: 0.9rem;
}

.menu-container-categorie  ul.liste  {list-style: none; margin:0; padding:0; border: 1px solid #b4af9d; padding:15px; background:#fff}
.menu-container-categorie  ul.liste #plans-montage,
.menu-container-categorie  ul.liste #attestations{cursor: pointer; font-weight:700}
.menu-container-categorie  ul.liste #plans-montage:after,
.menu-container-categorie  ul.liste #attestations:after {content:"\e901"; font-family: "Icomoon"; margin-left:10px; font-size: 12px;}
.menu-container-categorie  ul.liste .plans-montage,
.menu-container-categorie  ul.liste .attestations {visibility: visible;opacity: 1;height:auto; display:block;list-style: none;}
.menu-container-categorie  ul.liste .plans-montage.invisible,
.menu-container-categorie  ul.liste .attestations.invisible {visibility: hidden;opacity: 0;height:0px}
.menu-container-categorie  ul.liste li, #produits .menu-container-categorie  ul.liste li a  {color:#2C533A; text-transform: uppercase; font-size:14px;line-height: 1.7}
.menu-container-categorie  ul.liste>ul>li a {text-transform: initial !important;font-size:13px !important}
.menu-container-categorie  ul.liste .plans-montage,
.menu-container-categorie  ul.liste .attestations li a {font-size: 12px}


@media (max-width: 1030px) {
    .side-bar-details {
        display:none;
        }
    }

@media (max-width: 792px) {
#container-categorie {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    }
}
