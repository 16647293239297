.container-grid {
    margin: 0 auto;
    display: grid;
    grid-template-rows: 1fr auto 1fr;
    grid-template-columns: 1fr 4fr;
    gap: 8px;
    height: 100%;
    width: 85vw;
    max-width: 1600px;
}

.container-flex{
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    height: 100%;
    width: 85vw;
    max-width: 1600px;
}

/* --------@ MEDIA query 792PX -------- */
@media (max-width: 1030px) {
    .container-flex{

        justify-content: center;

    }

}
/* --------@ MEDIA query 792PX -------- */
@media (max-width: 792px) {


    .banner {
    margin-top: 2.85rem;
    }
    .container-grid {
        display: flex;
        flex-direction: column-reverse ;
        // width: 95vw;
    }

    .container-flex{
        width: 100%
    }
}

@import "./banner/_sidebar.scss";
@import "./banner/_products-grid.scss";
@import "./banner/_infos.scss";
