.angry-grid {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px;
    height: 100%;
  }

  #item-0 {
    grid-row: 1 / 4;
    grid-column: 5 / 8;
  }
  #items-container {
    grid-row: 1 / 4;
    grid-column: 8 / 10;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  #item-4 {
    grid-row: 3 / 4;
    grid-column: 1 / 5;
  }
  #item-5 {
    grid-row: 1 / 3;
    grid-column: 1 / 5;
  }
  .info-bloc-text, .info-bloc-paris, .info-bloc-contact {
    margin: 0.5rem;
    padding: 1rem 2rem;
    font-weight: 600;
    color: $text-color;
  }
.info-bloc-text {
  background-color: $primary;
  font-size: 0.8rem;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
 }

.info-bloc-paris {
  background-color: #9eac96;
  font-size: 0.9rem;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  display: flex;
  align-items: center;
}

.info-bloc-paris a {
    text-decoration: none;
    color: #000;
    font-size: 0.95rem;
    font-weight: 800;
}
.info-bloc-paris a:hover {

    color: #dbdbdb;


}
.info-bloc-contact {
 display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  border: #000 solid 1px;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  color: $tertiaire;
}

.info-bloc-contact h4 {
    font-size: 1.1rem;
    font-weight: 900;
    }
.info-bloc-contact p { font-size: 0.8rem;
    font-weight: 400;
}
.info-bloc-contact img { max-width: 100%;
    height: auto;
}
.info-bloc-contact-text { text-align: center;
    line-height: 2rem;
}


#item-1 img,#item-2 img,#item-3 img {
    width: calc(100% - 1rem);
    margin: 0.5rem;
}

/* --------@ MEDIA query 792PX Main container -------- */

@media (max-width: 792px) {

    .angry-grid {
        display: flex;
        flex-direction:column;

    }
    #items-container {
        grid-row: 1 / 4;
        grid-column: 8 / 10;
        display: flex;
        flex-direction: initial;
        height: 100%;
        justify-content: space-between;
    }
    #container-infos {
        grid-row: 3 / 4;
        grid-column: 1 / 2;
    }

}
