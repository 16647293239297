@import "./_variables.scss";
// Style du thème parent
@import "/themes/BootstrapTheme/assets/style/shop.scss";

/** GLOBAL CLASSES **/

html {
    height: 100%;
}
body {
    background-color: $background-color;
    height: auto;
    min-height: 100vh;
     display: flex;
    flex-direction: column;
}

p {
    margin-bottom: 0;
}

body a {
    text-decoration: none;
}

a p {
    color: $text-color;
}

.alert-info {
    --bs-alert-color: #063C1C;
    --bs-alert-bg: #98B13D;
    --bs-alert-border-color: #98B13D;
    --bs-alert-link-color: #063C1C;
}

@import "./layout/_btn_toggle.scss";
@import "./layout/header.scss";
@import "./layout/banner.scss";
@import "./layout/footer.scss";


/* --------@ MEDIA query 792PX -------- */

@media (max-width: 792px) {

    #container-categorie, #container-product, #container-infos {
        grid-row-start: auto;
        grid-row-end: auto;
        grid-column-start: auto;
        grid-column-end: auto;
    }
}

.img-show {
    width: 65%;
}

.container-70{
    width: 75%;
    max-width: 1090px;
}
.container-flex2 {
    margin: 1.5em auto 2.5rem;
    display: flex;
    justify-content: space-around;
    gap: 2rem;
    height: 100%;
    width: 85vw;
    max-width: 1350px;
}
