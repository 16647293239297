
footer {
    width: 100%;
    min-height: 180px;
    bottom: 0;
    background-color: $secondary;
    text-align: center;
    margin-top: 20px;
    padding: 20px;
    color:$text-color;

}

footer .footer{

    display: flex;
    flex-direction: column;
}


footer .footer-coordonnes p{

    font-weight: 400;
    color:$text-color;
    margin-bottom: 20px;

}

footer .footer-mention-legale a{

    text-decoration: none;
    color:$text-color!important;
    font-weight: 500;

}
footer .footer-mention-legale a:hover{

    text-shadow: 2px 2px 4px rgb(255, 255, 255);

}
footer .footer-mention-legale p{

    font-weight: 300;
    font-size: 0.9rem;
    max-width: 70vw;
    margin: auto;
}

/* --------@ MEDIA query 990PX -------- */
@media (max-width: 990px){
footer {
margin: 30px 0 0 0;
}
}
