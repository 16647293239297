
.header-menu {
    background-color: $primary!important;
}
.header-menu-responsive {
    display: none;
    background: rgb(180,175,157);
    background: linear-gradient(90deg, #2C5339 0%, #063C1C 100%);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);

}
.header-menu-responsive a{
    text-decoration: none;
    color: $text-color;

}
.navbar-toggler {
    border: 0.5px solid #ffffff26!important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
}
.navbar-toggler {
    border: none;
  }

  .navbar-toggler-icon {
    background-image: none !important;
    width: 25px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .navbar-toggler-icon::before,
  .navbar-toggler-icon::after,
  .navbar-toggler-icon > span {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 25%;
    background-color: $background-color;
  }


  .navbar-toggler-icon > span {
    height: 3px;
    border-radius: 25%;
    background-color: $background-color;
  }


.menu-responsive-open {
    background-color: rgba(91, 92, 67, 0.9);

}
.menu-responsive-open h5 {
    color: $text-color;

}
.infos-nav-responsive-open p,.infos-nav-responsive-open a{
    color: $text-color;
    text-align: center;
}
.infos-nav-responsive-open img {
    margin: auto;
}

.menu-link, .menu-link:focus, .menu-link:active {
     background-color: $primary;
     color: $text-color;
     border-right: 1px solid $background-color;
     padding: 0.5rem 1rem;
 }
 .nav-item:last-child .menu-link {
    border-right: none;
 }

 .menu-link:hover {
    background-color: $tertiaire;
     color: white;
 }


 header .head {

    padding: 0 0;
    background-color: #2C5339;
    color: $text-color;
    box-shadow: 1px 5px 5px #00000047;
 }

.dropdown-menu.show {
    background-color: $primary;

    border-right: 1px solid $background-color;
    padding: 0.5rem 1.5rem;
}
.dropdown-item {

    color: $text-color!important;
}
.dropdown-item:hover {

    background-color: $tertiaire;
    color: white;
    font-weight: 900;

}



@media (max-width: 792px) {

    #menu-desktop {
        display: none;
    }

 .header-menu {
        display: none;

    }
    .header-menu-responsive {
        display: block;
    }

    .nav-link, .nav-link:focus, .nav-link:active {
        border: none;
    }
    .signin-signup {
        justify-content: end;
    }
}


