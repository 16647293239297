.banner {
    width: 100%;
    height: 220px;
    background-color: #0f350f2e;
}
.banner-header {
    background-image: url("../../../media/images/banner3.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0%;
    width: 100%;
}

#slug-home-header {
    display: block;
    width: 70%;
    height: 100%;
    margin: auto;
}

.header-menu-electrique {
    display: flex;
}

#mainNav {
    justify-content: center;
}
.header-menu-electrique {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 1rem;
    max-width: 1450px;
  }

  .header-menu-electrique a{
    width: 300px;
    height: 100px;
    margin: 8px;
     display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-menu-electrique a img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #fff;
  }

  .header-menu-electrique a:hover img {
    filter: brightness(1.2);
    transition: all 0.3s ease;
  }

  .btn-menu-electrique{
    position: relative;
}
.header-menu-electrique span{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size:1rem;
  font-weight: bolder;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    width: 60%;
}

.header-menu-top {
    width : 100%;
    background-color : #063C1C;

}

.text-center {
    color:#2C5339
}
/* --------@ MEDIA query 792PX -------- */
@media (max-width: 792px) {



}

